import { getRandom, getRangeValue } from "tsparticles-engine";
import { Tilt } from "./Options/Classes/Tilt";
function updateTilt(particle, delta) {
    var _a, _b;
    if (!particle.tilt || !particle.options.tilt) {
        return;
    }
    const tilt = particle.options.tilt, tiltAnimation = tilt.animation, speed = ((_a = particle.tilt.velocity) !== null && _a !== void 0 ? _a : 0) * delta.factor, max = 2 * Math.PI, decay = (_b = particle.tilt.decay) !== null && _b !== void 0 ? _b : 1;
    if (!tiltAnimation.enable) {
        return;
    }
    switch (particle.tilt.status) {
        case 0:
            particle.tilt.value += speed;
            if (particle.tilt.value > max) {
                particle.tilt.value -= max;
            }
            break;
        case 1:
        default:
            particle.tilt.value -= speed;
            if (particle.tilt.value < 0) {
                particle.tilt.value += max;
            }
            break;
    }
    if (particle.tilt.velocity && decay !== 1) {
        particle.tilt.velocity *= decay;
    }
}
export class TiltUpdater {
    constructor(container) {
        this.container = container;
    }
    getTransformValues(particle) {
        var _a;
        const tilt = ((_a = particle.tilt) === null || _a === void 0 ? void 0 : _a.enable) && particle.tilt;
        return {
            b: tilt ? Math.cos(tilt.value) * tilt.cosDirection : undefined,
            c: tilt ? Math.sin(tilt.value) * tilt.sinDirection : undefined,
        };
    }
    init(particle) {
        var _a;
        const tiltOptions = particle.options.tilt;
        if (!tiltOptions) {
            return;
        }
        particle.tilt = {
            enable: tiltOptions.enable,
            value: (getRangeValue(tiltOptions.value) * Math.PI) / 180,
            sinDirection: getRandom() >= 0.5 ? 1 : -1,
            cosDirection: getRandom() >= 0.5 ? 1 : -1,
        };
        let tiltDirection = tiltOptions.direction;
        if (tiltDirection === "random") {
            const index = Math.floor(getRandom() * 2);
            tiltDirection = index > 0 ? "counter-clockwise" : "clockwise";
        }
        switch (tiltDirection) {
            case "counter-clockwise":
            case "counterClockwise":
                particle.tilt.status = 1;
                break;
            case "clockwise":
                particle.tilt.status = 0;
                break;
        }
        const tiltAnimation = (_a = particle.options.tilt) === null || _a === void 0 ? void 0 : _a.animation;
        if (tiltAnimation === null || tiltAnimation === void 0 ? void 0 : tiltAnimation.enable) {
            particle.tilt.decay = 1 - getRangeValue(tiltAnimation.decay);
            particle.tilt.velocity = (getRangeValue(tiltAnimation.speed) / 360) * this.container.retina.reduceFactor;
            if (!tiltAnimation.sync) {
                particle.tilt.velocity *= getRandom();
            }
        }
    }
    isEnabled(particle) {
        var _a;
        const tiltAnimation = (_a = particle.options.tilt) === null || _a === void 0 ? void 0 : _a.animation;
        return !particle.destroyed && !particle.spawning && !!(tiltAnimation === null || tiltAnimation === void 0 ? void 0 : tiltAnimation.enable);
    }
    loadOptions(options, ...sources) {
        if (!options.tilt) {
            options.tilt = new Tilt();
        }
        for (const source of sources) {
            options.tilt.load(source === null || source === void 0 ? void 0 : source.tilt);
        }
    }
    update(particle, delta) {
        if (!this.isEnabled(particle)) {
            return;
        }
        updateTilt(particle, delta);
    }
}
